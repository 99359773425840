import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/images/moving-logo.svg';
import routesList from '../../pages/routesList';
import styled from '../../styles';
import { getColorFromTheme } from '../../utils/theme';

const Header = () => {
  const history = useHistory();

  const goHome = () => {
    history.push(routesList.HOME);
  };

  return (
    <HeaderContainer>
      <HeaderContent>
        <Image alt="Logo" onClick={goHome} src={Logo} />
      </HeaderContent>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header((props) => ({
  alignItems: 'center',
  backgroundColor: getColorFromTheme(props, 'white'),
  borderBottom: `1px solid ${getColorFromTheme(props, 'white')}`,
  display: 'flex',
  height: '60px',
  justifyContent: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 5,
}));

const HeaderContent = styled.div(() => ({
  '@media (max-width:1080px)': {
    maxWidth: '640px',
  },
  '@media (max-width:720px)': {
    maxWidth: '87.5%',
  },
  maxWidth: '960px',
  width: '100%',
}));

const Image = styled.img({
  cursor: 'pointer',
});

export default memo(Header);
