import React, { lazy, memo, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';
import { retryPromise } from '../utils/errors';
import routesList from './routesList';

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, [history]);
  return null;
};

const Home = lazy(() => retryPromise(() => import(/* webpackChunkName: "Home" */ './Home')));

const Form = lazy(() => retryPromise(() => import(/* webpackChunkName: "Home" */ './Form')));

const Confirmation = lazy(() => retryPromise(() => import(/* webpackChunkName: "Confirmation" */ './Confirmation')));

const Pages = () => (
  <Router>
    <ScrollToTop />
    <Header />
    <Suspense fallback={<div>Loading Page...</div>}>
      <Switch>
        <Redirect exact from="/" to={routesList.HOME} />
        <Route exact path={routesList.HOME} component={Home} />
        <Route exact path={routesList.MOVING_FORM} component={Form} />
        <Route exact path={routesList.CONFIRMATION} component={Confirmation} />
      </Switch>
    </Suspense>
    <Footer />
  </Router>
);

export default memo(Pages);
