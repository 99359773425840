import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TmpEntry, TmpInitialState, TmpState } from './state';

export default createSlice({
  initialState: TmpInitialState,
  name: 'tmp',
  reducers: {
    errorTmp,
    startTmp,
    successTmp,
  },
});

function startTmp(state: TmpState) {
  state.error = undefined;
  state.loading = true;
  state.tmpEntries = [];
}

function successTmp(state: TmpState, action: PayloadAction<Array<Partial<TmpEntry>>>) {
  state.error = undefined;
  state.loading = false;
  state.tmpEntries = action.payload;
}

function errorTmp(state: TmpState, action: PayloadAction<any>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  state.error = action.payload;
  state.loading = false;
  state.tmpEntries = [];
}
