import styled from '@emotion/styled';
import React from 'react';

const Grid = styled.div<Grid>(
  ({ align = 'inherit', colLarge, colMedium, colSmall = 12, isRow, mobileAlign = 'center' }: Grid) => ({
    '@media (max-width:1080px)': {
      width: `${(100 * (colMedium || colLarge)) / 12}%`,
    },
    '@media (max-width:720px)': {
      textAlign: mobileAlign,
      width: `${(100 * (colSmall || colMedium || colLarge)) / 12}%`,
    },
    display: isRow ? 'block' : 'inline-block',
    margin: '0 auto',
    textAlign: align,
    verticalAlign: 'top',
    width: `${(100 * colLarge) / 12}%`,
  }),
);

interface Grid {
  align?: Align;
  colLarge: Col;
  colMedium?: Col;
  colSmall?: Col;
  children: React.ReactChild | React.ReactChild[];
  mobileAlign?: Align;
  isRow?: boolean;
}
type Align = 'left' | 'center' | 'right' | 'inherit';
type Col = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export default Grid;
