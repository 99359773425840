export const TmpInitialState: TmpState = {
  error: undefined,
  loading: false,
  tmpEntries: [],
};

export interface TmpState {
  error: any;
  loading: boolean;
  tmpEntries: TmpEntry[];
}

export interface TmpEntry {
  [k: string]: unknown;
}
