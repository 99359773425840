import { css } from '@emotion/core';
import styled, { CreateStyled } from '@emotion/styled';

import InterMedium from './assets/fonts/Inter/Inter-Medium.woff2';
import InterRegular from './assets/fonts/Inter/Inter-Regular.woff2';
import InterSemiBold from './assets/fonts/Inter/Inter-SemiBold.woff2';

export const styles = css`
  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: ${`url(${InterRegular}) format('truetype')`};
  }
  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: ${`url(${InterMedium}) format('truetype')`};
  }
  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: ${`url(${InterSemiBold}) format('truetype')`};
  }

  * {
    box-sizing: border-box;
    font-family: Inter;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }
  .text-align--left {
    text-align: left;
  }
  .text-align--center {
    text-align: center;
  }
  .text-align--right {
    text-align: right;
  }
  @media (min-width: 992px) {
    .container {
      max-width: 964px;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
  }

  input[type='date']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  .disabled-chart {
    background-color: #f6f6f6 !important;
    filter: grayscale(1);
  }

  .disabled-chart .chart-container {
    pointer-events: none;
    opacity: 0.2;
  }
`;

export const Theme = {
  borderRadius: '5px',
  buttonRadius: '15px',
  colors: {
    blue: '#2A87EB',
    // TODO: These two colors are not defined in the 'systems' site
    border: '#D6D6D6',
    disabled: '#CCC',
    fill: '#FFF',
    fillSecondary: '#FAFAFA',
    fillTertiary: '#F2F2F2',
    gray: '#000',
    grayQuaternary: '#999',
    graySecondary: '#666',
    grayTertiary: '#888',
    green: '#009E32',
    orange: '#EB9313',
    red: '#B82421',
    white: '#FFF',
  },
  fontSize: 12,
  spacing: {
    padding: {
      large: '1.072em',
      normal: '0.5em 0.75em',
      small: '0.25em 0.5em',
    },
  },
};

export type AppColorStrings = keyof typeof Theme.colors;

export type ThemeType = typeof Theme;

export default styled as CreateStyled<ThemeType>;
