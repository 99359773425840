/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

class SegmentAnalytics {
  static isInitialized: boolean;
  createAnalyticsObject = (analyticsKey: string) => {
    // Create a queue, but don't obliterate an existing one!
    const analytics = ((window as any).analytics = (window as any).analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) {
      return;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      throw new Error('Segment snippet included twice.');
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = (method: string) => {
      return function createdMethod(...args: any[]) {
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (const method of analytics.methods) {
      analytics[method] = analytics.factory(method);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = (key: string, options?: any) => {
      // Create an async script element based on your key.
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

      // Insert our script next to the first script element.
      const first = document.getElementsByTagName('script')[0];
      if (!first || !first.parentNode) {
        return;
      }
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.1.0';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(analyticsKey);
    SegmentAnalytics.isInitialized = true;
  };

  getAnalyticsObject = (): Analytics => {
    if (!SegmentAnalytics.isInitialized) {
      const stagingKey = 'cKTD0DPwQ9VscrnmulwuXXyXVKu4kzAB';
      const key = process.env.REACT_APP_SEGMENT_ANALYTICS_KEY || stagingKey;
      this.createAnalyticsObject(key);
    }

    return (window as any).analytics;
  };

  trackPage = (pageName: string, properties?: any): void => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    this.getAnalyticsObject().page(`Views page`, {
      category: 'Global',
      screenName: pageName,
      ...properties,
    });
  };

  trackEvent = (eventName: string, properties: any): void => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    this.getAnalyticsObject().track(eventName, {
      ...properties,
      pageUrl: window.location.href,
    });
  };

  identifyUser = (userId: string, properties?: any): void => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const analytics = this.getAnalyticsObject();
    analytics.alias(userId);
    analytics.identify(userId, {
      ...properties,
    });
  };
}

interface Analytics {
  identify: (userId?: string, traits?: any, options?: any, callback?: () => void) => void;
  alias: (userId: string) => void;
  track: (category: string, event: string, properties?: any, options?: any, callback?: () => void) => void;
  page: (category: string, name?: string, properties?: any, options?: any, callback?: () => void) => void;
  load: (key: string) => void;
}

export const segmentAnalytics = new SegmentAnalytics();
