import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import React, { memo } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import Pages from './pages';
import { store } from './redux';
import { styles, Theme } from './styles';

const persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <Global styles={styles} />
      <ThemeProvider theme={Theme}>
        <PersistGate loading={null} persistor={persistor}>
          <Pages />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
}

export default memo(App);
