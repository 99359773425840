import styled, { AppColorStrings } from '../../styles';
import { getColorFromTheme } from '../../utils/theme';

const TypographyBase = styled.p<TypographyBase>((props) => ({
  color: props.color ? getColorFromTheme(props, props.color) : 'inherit',
}));

interface TypographyBase {
  color?: AppColorStrings;
}

export const HeadlineStandalone = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 80,
    lineHeight: '84px',
  },
  '@media (max-width: 720px)': {
    fontSize: 48,
    lineHeight: '52px',
  },
  fontSize: 96,
  fontWeight: 600,
  lineHeight: '100px',
})).withComponent('h1');

export const HeadlineSuper = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 64,
    lineHeight: '68px',
  },
  '@media (max-width: 720px)': {
    fontSize: 48,
    lineHeight: '52px',
  },
  fontSize: 80,
  fontWeight: 600,
  lineHeight: '84px',
})).withComponent('h1');

export const HeadlineElevated = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 48,
    lineHeight: '52px',
  },
  '@media (max-width: 720px)': {
    fontSize: 40,
    lineHeight: '44px',
  },
  fontSize: 64,
  fontWeight: 600,
  lineHeight: '68px',
})).withComponent('h1');

export const Headline = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 40,
    lineHeight: '44px',
  },
  '@media (max-width: 720px)': {
    fontSize: 32,
    lineHeight: '36px',
  },
  fontSize: 48,
  fontWeight: 600,
  lineHeight: '52px',
})).withComponent('h1');

export const HeadlineReduced = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 32,
    lineHeight: '36px',
  },
  '@media (max-width: 720px)': {
    fontSize: 28,
    lineHeight: '32px',
  },
  fontSize: 40,
  fontWeight: 600,
  lineHeight: '44px',
})).withComponent('h1');

export const Quote = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 32,
    lineHeight: '40px',
  },
  '@media (max-width: 720px)': {
    fontSize: 28,
    lineHeight: '36px',
  },
  fontSize: 40,
  lineHeight: '48px',
}));

export const QuoteReduced = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 28,
    lineHeight: '36px',
  },
  '@media (max-width: 720px)': {
    fontSize: 24,
    lineHeight: '32px',
  },
  fontSize: 32,
  lineHeight: '40px',
}));

export const Callout = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 28,
    lineHeight: '32px',
  },
  '@media (max-width: 720px)': {
    fontSize: 24,
    lineHeight: '28px',
  },
  fontSize: 32,
  fontWeight: 600,
  lineHeight: '36px',
}));

export const Label = styled(TypographyBase)(() => ({
  '@media (max-width: 1080px)': {
    fontSize: 20,
    lineHeight: '24px',
  },
  fontSize: 24,
  fontWeight: 600,
  lineHeight: '28px',
}));

export const Intro = styled(TypographyBase)(() => ({
  '@media (max-width: 720px)': {
    fontSize: 18,
    lineHeight: '26px',
  },
  fontSize: 20,
  lineHeight: '28px',
}));

export const Tout = styled(TypographyBase)(() => ({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '22px',
}));

export const Body = styled(TypographyBase)(() => ({
  fontSize: 16,
  lineHeight: '24px',
}));

export const BodyTight = styled(TypographyBase)(() => ({
  fontSize: 16,
  lineHeight: '20px',
}));

export const BodyReduced = styled(TypographyBase)(() => ({
  fontSize: 14,
  lineHeight: '20px',
}));

export const BodyReducedTight = styled(TypographyBase)(() => ({
  fontSize: 14,
  lineHeight: '18px',
}));

export const Caption = styled(TypographyBase)(() => ({
  fontSize: 12,
  lineHeight: '16px',
}));
