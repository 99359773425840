import React, { memo } from 'react';

import styled from '../../styles';
import { getColorFromTheme } from '../../utils/theme';
import ExternalLink from '../ExternalLink';
import Grid from '../Grid';
import { Caption } from '../Typography';

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <Grid colLarge={6}>
        <Caption>Copyright © 2020 Alfred Club. All rights reserved.</Caption>
      </Grid>
      <Grid align="right" colLarge={6}>
        <Caption>
          <ExternalLink content="Privacy Policy" href="https://helloalfred.com/privacy-policy/" />
          {' | '}
          <ExternalLink content="Terms of Use" href="https://helloalfred.com/terms-of-use/" />
        </Caption>
      </Grid>
    </FooterContent>
  </FooterContainer>
);

const FooterContainer = styled.footer((props) => ({
  '@media (max-width: 720px)': {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    position: 'relative',
    textAlign: 'center',
  },
  alignItems: 'center',
  backgroundColor: getColorFromTheme(props, 'white'),
  borderBottom: `1px solid ${getColorFromTheme(props, 'white')}`,
  display: 'flex',
  height: '60px',
  justifyContent: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 5,
}));

const FooterContent = styled.div((props) => ({
  '& a': {
    color: getColorFromTheme(props, 'grayTertiary'),
  },
  '@media (max-width:1080px)': {
    maxWidth: '640px',
  },
  '@media (max-width:720px)': {
    maxWidth: '87.5%',
  },
  color: getColorFromTheme(props, 'grayTertiary'),
  height: '80px',
  maxWidth: '960px',
  width: '100%',
}));

export default memo(Footer);
