/* eslint-disable @typescript-eslint/no-floating-promises */
// Thanks to https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export function retryPromise<T>(fn: () => Promise<T>, retriesLeft = 5, interval = 1000) {
  return new Promise<T>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
