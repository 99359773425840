import React from 'react';

import styled, { AppColorStrings } from '../../styles';
import { segmentAnalytics } from '../../utils/analytics';
import { getColorFromTheme } from '../../utils/theme';

const ExternalLink = (props: ExternalLink) => {
  const { content, href, trackClick } = props;

  const onClick = (href: string) => {
    if (trackClick) {
      trackClick();
    } else {
      segmentAnalytics.trackEvent('Clicks on external link', {
        category: 'Global',
        href,
      });
    }
  };
  return (
    <Link href={href} onClick={() => onClick(href)} rel="noopener noreferrer" target="_blank">
      {content}
    </Link>
  );
};

const Link = styled.a<Link>((props) => ({
  color: getColorFromTheme(props, props.color || 'gray'),
}));

interface ExternalLink {
  content: string | JSX.Element;
  href: string;
  trackClick?: () => void;
}

interface Link {
  color?: AppColorStrings;
}

export default ExternalLink;
